@import "../../styles/mainStyles.scss";

.container {
  @include s {
    flex-direction: column-reverse;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  @include s {
    gap: 1rem;
  }
}

.title {
  @include title;
  display: inline-block;
  width: auto;
}

.description {
  @include description;
}