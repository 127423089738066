@import '../../styles/mainStyles.scss';

.swiperSlide {
  transition: .25s;
}
.slide {
  width: 100%;
  height: 100%;
}

.horizontal {
  bottom: -4rem !important;
  width: 100% !important;
  @include s {
    width: 100% !important;
  }
}
.bullet, .bulletActive {
  width: .875rem;
  height: .875rem;
  display: inline-block;
  border-radius: 50%;
  background: $light-twenty-five;
  @include s {
    display: none;
  }
}
.bulletActive {
  background: $light;
}