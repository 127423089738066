@import "../../../styles/mainStyles.scss";

.container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  @include s {
    max-width: 15.438rem;
    margin: 0;
  }
}
.card {
  height: 11.75rem;
  width: 100%;
}
.title {
  padding: 1.75rem 0 .75rem;
  @include SairaRegular;
  color: $light;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 90%;
}
.description {
  @include SairaRegular;
  color: $light;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;
}