@import "../../styles/mainStyles.scss";

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  padding: 4rem 0;
  gap: 1.75rem;
  @include s {
    padding: 0;
    gap: 1rem;
  }
}

.title {
  @include title;
  @include s {
    font-size: 2.5rem;
  }
}

.description {
  @include description;
}