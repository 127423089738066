@import "../../styles/mainStyles.scss";

.container {
  @include s {
    flex-direction: column-reverse;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  gap: 1.75rem;
  @include s {
    gap: 1rem;
  }
}

.title {
  @include title;
  @include s {
    br {
      display: none;
    }
  }
}

.description {
  @include description;
}

.button {
  width: 65%;

  @include m {
    width: 70%;
  }
  @include s {
    width: 100%;
  }
}