@import "../../styles/mainStyles.scss";

.section {
  background-image: url('../../assets/images/BACK3.svg');
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  gap: 1.75rem;
  @include s {
    gap: 1rem;
  }
}

.title {
  @include title;
  @include s {
    br {
      &:last-child {
        display: none;
      }
    }
  }
}

.description {
  @include description;
}