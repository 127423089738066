@import '../../styles/mainStyles.scss';

.section {
  background: linear-gradient(0deg, $dark 60%, transparent 55%);
}

.container {
  flex-direction: row;
  justify-content: center;
  gap: 1.188rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;

  @include s {
    gap: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  @include xs {
    gap: .5rem;
  }
}
.card {
  position: relative;
  height: 23rem;
  width: 33.333%;
  @include xl {
    height: 20.875rem;
    img {
      width: 7.5rem;
    }
  }
  @include l {
    height: 18.875rem;
    img {
      width: 6.5rem;
    }
  }
  @include m {
    height: 17.875rem;
    img {
      width: 6rem;
    }
  }
  @include s {
    height: 12.875rem;
    img {
      width: 4.5rem;
    }
  }
  @include xs {
    height: 9.375rem;
    img {
      width: 3.125rem;
    }
  }
}
