@import "../../styles/mainStyles.scss";

.dropdown {
  position: relative;
  z-index: 3;
  width: 100%;

  &.active,
  &.focus-within {
    z-index: 10;

    .head {
      .arrow {
        img {
          transform: rotate(180deg);
        }
      }
    }

    .body,
    .bodyContainer {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  @include s {
    width: 80%;
  }
}

.head {
  position: relative;
  display: flex;
  align-items: center;

  padding: 0 3rem 0 1.5rem;

  height: 3.375rem;

  @include SairaRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 3rem;

  cursor: pointer;

  border-radius: 2rem;
  box-shadow: inset 0 0 0 2px $light;

  transition: box-shadow $animation-timing-default;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  // @include dark {
  //   box-shadow: inset 0 0 0 1px $neutrals3;
  // }

  &:hover,
  &:active,
  &:focus-within {
    box-shadow: inset 0 0 0 1px $light-fifty;
    // @include dark {
    //   box-shadow: inset 0 0 0 1px $neutrals5;
    // }
  }
}

.avatar {
  margin-right: .375rem;

  width: 1.5rem;
  height: 1.5rem;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.selection {
  overflow: hidden;
  color: $light;

  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  // @include dark {
  //   color: $neutrals4;
  // }
}

.arrow {
  position: absolute;
  top: 50%;
  right: 1.25rem;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 1rem;
  width: .688rem;
  height: 1rem;
  height: .375rem;

  border-radius: 50%;
  // box-shadow: inset 0 0 0 1px $neutrals6;

  transform: translateY(-50%);

  img {
    // fill: $neutrals4;

    transition: transform $animation-timing-default;
  }

  // @include dark {
  //   box-shadow: inset 0 0 0 1px $neutrals3;
  // }
}

.bodyContainer{
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  left: 0;

  padding: .5rem 0;
  background: $dark;
  // background: transparent;
  border-radius: 12px;
  opacity: 0;
  box-shadow: 0 0 0 1px $light-twenty-five; //, 0 4px 12px rgba($neutrals2, 0.1);

  visibility: hidden;

  transition: all $animation-timing-default;
  transform: translateY(3px);

  // @include dark {
  //   background: $neutrals1;
  //   box-shadow: 0 0 0 1px $neutrals3, 0 4px 12px rgba($neutrals1, 0.1);
  // }
}

.body {
  max-height: 12rem;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 1px;

  &::-webkit-scrollbar {
    width: 5px;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-thumb {
    padding: 8px 0;
    background-color: $light-twenty-five !important;
    background: $light-twenty-five !important;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $light-fifty !important;
    background: $light-fifty !important;
    // @include dark {
    //   background-color: $neutrals1 !important;
    //   background: $neutrals1 !important;
    // }
  }
}

.option {
  display: flex;
  align-items: center;
  padding: .25rem 1rem;

  @include SairaRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: $light-fifty;

  cursor: pointer;

  transition: color $animation-timing-default;
  -webkit-tap-highlight-color: $light-fifty;

  &:hover,
  &.selected {
    font-weight: bold;
    color: $light-fifty;
    // @include dark{
    //   color: $neutrals5;
    // }
  }
}

.image {
  width: 2.5em;
  padding: .1rem 0;
}

.text {
  @include SairaRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: $light-fifty;
  margin-left: .5rem;
  // @include dark{
  //   color: $neutrals4;
    &:hover {
      color: $light;
    }
  // }
}

.selectioned {
  font-weight: bold;

  color: $light-fifty;
}
