@import '../../styles/mainStyles.scss';

.card {
  box-sizing: border-box;
  border: 2px solid $light-twenty-five;
  background: linear-gradient(276.71deg, rgba(217, 217, 217, 0.05) 12.59%, rgba(217, 217, 217, 0) 104.89%);
  backdrop-filter: blur(11px);
  border-radius: 10px;
  position: relative;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  cursor: pointer;
  transition: .5s;
  img {
    transition: .5s;
  }

  &:hover {
    img {
      transition: .5s;
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  &.onHover {
    transition: .5s;
    @media only screen and (min-width: 769px)
    {
      &:hover {
        transition: .5s;
        border: 2px solid transparent;
        -webkit-mask:
        linear-gradient($dark 0 0) content-box,
        linear-gradient($dark 0 0);
        position: relative;

        &::before {
          transition: .5s;
          content: "";
          position: absolute;
          inset: 0;
          z-index: -1;
          border-radius: 10px;
          padding: 2px;
          margin: -2px;
          background: $gradient-blockchain;
          -webkit-mask:
          linear-gradient($dark 0 0) content-box,
          linear-gradient($dark 0 0);
          -webkit-mask-composite: xor;
                  mask-composite: exclude;
        }
      }
    }
  }

  &.comingSoon {
    img {
      opacity: .5;
    }
    &::before {
      content: "Coming Soon";
      text-transform: uppercase;
      position: absolute;
      font-size: .875rem;
      top: 0;
      right: 0;
      transform: translate(29.29%, -100%) rotate(45deg);
      color: $light;
      text-align: center;
      border: 2px solid transparent;
      border-bottom: 0;
      transform-origin: bottom left;
      padding: 6px 4rem 11px;
      background: $gradient-gamming;
      background-clip: padding-box;
      clip-path: polygon(0 0,100% 0,100% 100%,calc(100% - 1px) calc(100% - 6px),1px calc(100% - 6px),0 100%);
      -webkit-mask: linear-gradient(135deg,transparent calc(50% - 4px * 0.707),#fff 0) bottom left,
        linear-gradient(-135deg, transparent calc(50% - 4px * 0.707), #fff 0)bottom right;
      -webkit-mask-size: 300vmax 300vmax;
      mask-composite: intersect;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 2rem;

      @include l {
        font-size: .675rem;
        padding: 0 3.5rem .313rem;
      }
      @include s {
        width: 75%;
        font-size: .65rem;
        height: 1.5rem;
        padding: .25rem 1rem .5rem;
      }
      @include xs {
        // height: 1.5rem;
        font-size: .4rem;
        padding: 0 .9rem .213rem;
      }
    }
  }

  &.topLabel {
    @include s {
      border: 2px solid transparent;
      -webkit-mask:
      linear-gradient($dark 0 0) content-box,
      linear-gradient($dark 0 0);
      position: relative;

      &::before {
        transition: .5s;
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        border-radius: 10px;
        padding: 1.5rem 2px 2px 2px;
        margin: -2px;
        background: $gradient-gamming;
        -webkit-mask:
        linear-gradient($dark 0 0) content-box,
        linear-gradient($dark 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
      }
    }
  }

  @include s {
    height: 13.5rem;
    img {
      width: 5.5rem;
    }
  }
}

.typeContainer {
  position: absolute;
  top: .75rem;
  margin: 0 -.75rem;
  padding: 1rem .75rem;
  background: linear-gradient(276.71deg, rgba(217, 217, 217, 0.1) 12.59%, rgba(217, 217, 217, 0) 104.89%);
  backdrop-filter: blur(5.5px);
  /* Note: backdrop-filter has minimal browser support */
  border: 2px solid $light-twenty-five;
  border-radius: 10px;
  transform: rotate(180deg);
  width: 87%;

  @include l {
    padding: .9rem .5rem;
  }
  @include m {
    padding: .875rem 0;
  }
  @include s {
    display: none;
  }
}
.type {
  transform: rotate(-180deg);
  @include SairaRegular;
  font-weight: 400;
  font-size: 1rem;
  line-height: 25px;
  text-align: center;
  color: white;

  @include m {
    font-size: .875rem;
  }
}

.clickLabel {
  display: none;
  @include SairaRegular;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  text-align: center;

  color: white;
  position: absolute;
  top: .25rem;

  @include s {
    display: inline-flex;
    font-size: .6rem;
  }
  @include xs {
    font-size: .5rem;
  }
}