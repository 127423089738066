@font-face {
  font-family: 'SairaRegular';
  src: url('../assets/fonts/Saira-Regular.ttf');
}
@mixin SairaRegular {
  font-family: SairaRegular;
}
@font-face {
  font-family: 'SairaExtraBold';
  src: url('../assets/fonts/Saira-ExtraBold.ttf');
}
@mixin SairaExtraBold {
  font-family: SairaExtraBold;
}