$gradient-blockchain: linear-gradient(90.11deg, #C830FF -2.06%, #6AC5EC 49.16%, #00FFB2 101.46%);
$gradient-gallery: linear-gradient(90deg, #C24075 0%, #FF914C 100%);
$gradient-marketing: linear-gradient(90deg, #4C93A0 0%, #9FCE7C 100%);
$gradient-gamming: linear-gradient(90deg, #ED7EFF 0%, #9156FA 60%, #7349F9 86%, #6945F9 100%);
$gradient-real-state: linear-gradient(90deg, #6BB2FF 0%, #68A8FE 8%, #5B79FB 50%, #535BF9 82%, #5051F9 100%);
$gradient-academy: linear-gradient(90deg, #FFD89C 0%, #FFA43D 100%);
$dark: #131517;
$dark-medium: #1E1F25;
$dark-light: #434351;
$light: #FDF4F4;
$light-fifty: rgba(253, 244, 244, 0.5);;
$light-twenty-five: rgba(253, 244, 244, 0.25);