// 320px — 480px: Mobile devices - xs
// 481px — 768px: iPads, Tablets - s
// 769px — 1024px: Small screens, laptops - m
// 1025px — 1200px: Desktops, large screens - l
// 1201px and more —  Extra large screens, TV - xl

// @mixin xl {
//   @media only screen and (max-width: 1419px) {
//     @content;
//   }
// }

@mixin xl {
  @media only screen and (max-width: 1401px) {
    @content;
  }
}

@mixin l {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin m {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin s {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin xs {
  @media only screen and (max-width: 480px) {
    @content;
  }
}