@import '../../styles/mainStyles.scss';

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .logo {
    width: 11rem;
  }
}

.dropdown {
  display: none;
}

.languageDropdownHead {
  height: 3rem;
  width: 3.125rem;
  box-shadow: inset 0 0 0 2px $light-twenty-five;
  padding: 0 2rem 0 .75rem;
}

.languageDropdownArrow {
  right: .75rem;
}