@import '../../styles/mainStyles.scss';

.container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;

  @include s {
    padding: 2rem 2rem 1rem;
    gap: 2rem;
  }
}

.logo {
  display: flex;
  flex-direction: column;

  img {
    width: 11rem;
  }
}

.slogan {
  @include SairaRegular;
  color: $light-fifty;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  padding: 2rem 0;
  // gap: 4rem;

  @include s {
    flex-direction: column;
    padding: 0 0;
    // gap: 1rem;
  }
}
.column {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}
.categoryColumn {
  @include m {
    display: none;
  }
}
.lastColumn {
  padding: 0;
  height: 100%;
  flex: auto;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  flex-grow: 3;
  gap: .5rem;

  @include s {
    flex-direction: column-reverse;
    gap: 1.75rem;
    align-items: flex-start;
  }
}

.category {
  @include SairaRegular;
  color: $light-fifty;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  padding-bottom: 2rem;
}

.link {
  @include SairaRegular;
  color: $light-twenty-five;
  font-style: normal;
  font-weight: 600;
  font-size: .875rem;
  line-height: 1rem;
  padding: .5rem 0;
}

.copyrightRow {
  justify-content: flex-start;
}

.borderTop {
  border-top: 1px solid $light-twenty-five;
  padding: 2rem 0 1rem;
}

.copyright {
  @include SairaRegular;
  font-style: normal;
  font-weight: 400;
  font-size: .75rem;
  line-height: 1.25rem;
  color: $light-fifty;
  font-feature-settings: "liga" off;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown {
  display: none;
}
.dropdownWrapper {
  justify-content: flex-end;
  align-items: flex-end;
}

.languageDropdownHead {
  height: 3rem;
  width: 3.125rem;
  box-shadow: inset 0 0 0 2px $light-twenty-five;
  padding: 0 2rem 0 .75rem;
}

.languageDropdownArrow {
  right: .75rem;
}

.socialsWrapper {
  justify-content: flex-end;
  align-content: flex-end;
}

.socials {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-bottom: auto;
  margin-top: auto;
  padding: 1rem 0 0;
}

.social {
  img {
    width: 1.375rem;
    height: 1.375rem;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
}