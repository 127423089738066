@import "../../styles/mainStyles.scss";

.section {
  background-image: url('../../assets/images/BACK5.svg');
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: space-around;
  gap: 1.75rem;
  @include s {
    gap: 1rem;
  }
}

.title {
  @include title;
}

.description {
  @include description;
}
.link{
  text-decoration: none;
  &:hover {
    img {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
}
.certik {
  width: 19.313rem;
  transition: .5s;
  @include xl {
    width: 17rem;
  }
  @include l {
    width: 14rem;
  }
  @include m {
    width: 13rem;
  }
  @include s {
    width: 12rem;
  }
}