@import "../../styles/mainStyles.scss";

.section {
  overflow: hidden;
}

.container {
  justify-content: flex-start;
  align-items: flex-start;

  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  margin-left: auto;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 3rem 0 2rem;
  overflow: hidden;
  width: 100%;
  @include s {
    padding: 1rem 0;
    justify-content: flex-end;
    width: 100%;
  }
}

.title {
  @include title;
}

.description {
  @include description;
}

.row {
  padding: 3rem 0 4rem;
  @include s {
    padding: 2rem 0;
  }
}

.sliderWrapper {
  padding: 3rem 0 4rem;
  @include s {
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
  }
}

.slider {
  overflow: visible !important;
  @include s {
    overflow: hidden !important;
  }
}

.slide {
  overflow: visible;
  height: 18.438rem !important;
  @include l {
    height: 16.423rem !important;
  }
  @include m {
    height: 15.423rem !important;
  }
  @include s {
    height: 13.4rem !important;
  }
}

.kardiachainLogo {
  @include s {
    img {
      width: 11.5rem !important;
    }
  }
}
.zurbaranLogo {
  @include s {
    img {
      width: 9rem !important;
    }
  }
}
.criptoKuanticaLogo {
  @include s {
    img {
      width: 11rem !important;
    }
  }
}
.beatbloxLogo {
  @include s {
    img {
      width: 10rem !important;
    }
  }
}
.rubicLogo {
  @include s {
    img {
      width: 11rem !important;
    }
  }
}
.chainlinkLogo {
  @include s {
    img {
      width: 11rem !important;
    }
  }
}
.kiLogo {
  @include s {
    img {
      width: 7rem !important;
    }
  }
}
.gtsLogo {
  @include s {
    img {
      width: 8.25rem !important;
    }
  }
}
.beInCryptoLogo {
  @include s {
    img {
      width: 10rem !important;
    }
  }
}

.redbee {
  @include s {
    img {
      width: 15rem !important;
    }
  }
}

.bitcourierLogo {
  @include s {
    img {
      width: 10rem !important;
    }
  }
}